<style>
</style>

<script>
  export let icon
  export let title
  export let description
  export let link
  export let number
  export let contact
  export let mail

  import Fa from 'fa-svelte'
  import { faUser } from '@fortawesome/free-solid-svg-icons'
  import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
  import { faPhone } from '@fortawesome/free-solid-svg-icons'
  import { faLaptop } from '@fortawesome/free-solid-svg-icons'
  import { fade } from 'svelte/transition'

  function followLink(link) {
    window.open(link, '_blank')
  }
</script>

<div class="bg-white rounded my-6">
  <div class="w-full h-24 bg-luhblue rounded-t"></div>
  <div class="flex justify-center">
    <img
      class="w-24 h-24 mx-auto rounded-full bg-white p-2 -mt-8"
      src="{icon}"
      alt="Service Icon"
    />
  </div>
  <p class="text-center font-bold text-gray-700 mb-2">{title}</p>
  <hr />
  <p class="p-4">{description}</p>
  <hr />
  <p class="text-gray-700 text-center font-semibold mt-2">Contact</p>
  <div class="flex">
    <div class=" w-full flex justify-center pb-3 text-gray-700 py-2">
      <div
        class="text-center mr-3 flex flex-col items-center text-grey-dark
        border-r pr-3"
      >
        <Fa class="text-center m-2" icon="{faUser}" />
        <span>{contact}</span>
      </div>
      <div
        class="text-center mr-3 flex flex-col items-center text-grey-dark
        border-r pr-3"
      >
        <Fa class="text-center m-2" icon="{faPhone}" />
        <span>{number}</span>
      </div>
      <a
        class="text-center flex flex-col items-center text-grey-dark mr-3"
        href="mailto:${mail}"
      >
        <Fa class="text-center m-2" icon="{faEnvelope}" />
        <span>E-Mail</span>
      </a>
    </div>
  </div>

  <a href="{link}" target="_">
    <button
      class="bg-luhblue w-full hover:bg-blue-700 focus:outline-none text-white
    font-bold py-2 px-4 rounded-b"
    >
      Open Service
    </button>
  </a>
</div>
