<style>
  .nav {
    min-height: 3.25rem;
  }
</style>

<script>
  import { Link } from 'svelte-routing'
  import Fa from 'fa-svelte'
  import SideBar from './MobileSideBar.svelte'
  import Hamburger from './Hamburger.svelte'
  import { faBars, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
  import { logout, isAuthenticated } from '../../store/auth-store.js'
  export let sidebar = false

  function getProps({ location, href, isPartiallyCurrent, isCurrent }) {
    const isActive = href === '/' ? isCurrent : isPartiallyCurrent || isCurrent

    // The object returned here is spread on the anchor element's attributes
    if (isActive) {
      return { class: 'active' }
    }
    return {}
  }
</script>

<div class="container mx-auto">
  <nav
    class="nav flex w-full items-center justify-between flex-wrap
  bg-white py-2"
  >
    <img class="h-8" src="/images/logo_irz.svg" alt="Not found" />
    {#if $isAuthenticated}
      <Hamburger bind:open="{sidebar}" />
      <ul
        class="hidden md:flex overflow-x-hidden items-center justify-around
      font-semibold"
      >
        <li class="md:ml-4 font-bold text-grey-darker hover:text-luhblue">
          <Link
            class="block no-underline px-4 font-bold py-2 text-luhblue
          hover:text-luh-purple md:border-none md:p-0"
            to="/services"
            getProps="{getProps}"
          >
            Services
          </Link>
        </li>
        <li class="md:ml-4 font-bold text-grey-darker hover:text-luhblue">
          <Link
            class="border-t block no-underline px-4 font-bold py-2 text-luhblue
          hover:luh-purple md:border-none md:p-0"
            to="/ips"
            getProps="{getProps}"
          >
            IPs
          </Link>
        </li>
        <li class="md:ml-4 font-bold text-grey-darker hover:text-luhblue">
          <Link
            class="border-t block no-underline px-4 font-bold py-2 text-luhblue
          hover:luh-purple md:border-none md:p-0"
            to="/users"
            getProps="{getProps}"
          >
            Users
          </Link>
        </li>
        <li class="md:ml-4">
          <button
            class="bg-transparent hover:bg-luhblue text-luhblue font-semibold
          hover:text-white py-2 px-4 border border-blue-500
          hover:border-transparent rounded inline-flex items-center"
            type="button"
            on:click="{logout}"
          >
            <Fa class="w-4" icon="{faSignOutAlt}" />
          </button>
        </li>
      </ul>
    {/if}
  </nav>
</div>
