<style global lang="postcss">
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap');
  body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;

    font-family: 'Roboto', sans-serif;
  }

  main {
    flex: 1; /* Or flex-grow: 1;*/
    @apply bg-grey-lighter;
  }
</style>

<script>
  import { onMount } from 'svelte'
  import { Router, Route } from 'svelte-routing'
  import Services from './components/services/ServicesList.svelte'
  import IpList from './components/ips/IpList.svelte'
  import UserList from './components/users/UserList.svelte'
  import NavBar from './components/base/NavBar.svelte'
  import SideBar from './components/base/MobileSideBar.svelte'
  import Footer from './components/base/Footer.svelte'
  import Login from './components/login/Login.svelte'
  import ApiService from './api-service/api'
  import { token, isAuthenticated } from './store/auth-store.js'
  import { navigate } from 'svelte-routing'

  let open = false
  let showNav = false
  let innerWidth

  export let url = ''

  $: if (innerWidth > 768) {
    open = false
  }

  onMount(() => {
    if ($isAuthenticated) {
      ApiService.setAuthorizationHeader($token)
    }
  })
</script>

<svelte:window bind:innerWidth />

<Router url="{url}">
  <SideBar bind:open />
  <NavBar bind:sidebar="{open}" />
  <main class="main">
    <Route path="/" component="{Login}" />
    <Route path="/services" component="{Services}" />
    <Route path="/ips" component="{IpList}" />
    <Route path="/users" component="{UserList}" />
  </main>
  <Footer />
</Router>
