<script>
  import { onMount } from 'svelte'
  import Icon from 'fa-svelte'
  import {
    faUser,
    faTerminal,
    faHome,
    faIdCardAlt,
  } from '@fortawesome/free-solid-svg-icons'

  import { users, fetch } from '../../store/users.js'

  onMount(() => {
    fetch()
  })
</script>

<div class="container mx-auto mt-6">
  <div
    class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4"
  >
    {#if $users}
      {#each $users as user}
        <div class="bg-white rounded p-2 text-gray-700">
          <div>
            <div class="inline-flex items-center">
              <Icon class="mr-2 text-gray-700" icon="{faUser}" />
              <span>{user.givenName} {user.sn}</span>
            </div>
          </div>
          <div>
            <div class="inline-flex items-center">
              <Icon class="mr-2 text-gray-700" icon="{faIdCardAlt}" />
              <span>{user.uidNumber}:{user.gidNumber}</span>
            </div>
          </div>
          <div>
            <div class="inline-flex items-center">
              <Icon class="mr-2 text-gray-700" icon="{faHome}" />
              <span>{user.unixHomeDirectory}</span>
            </div>
          </div>
          <div>
            <div class="inline-flex items-center">
              <Icon class="mr-2 text-gray-700" icon="{faTerminal}" />
              <span>{user.loginShell}</span>
            </div>
          </div>
        </div>
      {/each}
    {/if}
  </div>
</div>
