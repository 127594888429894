<style>
  .copy:before {
    content: ' \00A9';
  }
</style>

<script>
  import { onMount } from 'svelte'
  let now = new Date(),
    month,
    day,
    year

  onMount(() => {
    year = now.getFullYear()
  })
</script>

<footer
  class="w-full text-center border-t border-grey text-luhblue p-4 pin-b"
  style="background: white"
>
  <span class="copy">{` 2017 - ${year} `}</span>
  <a target="_blank" href="https://www.irz.uni-hannover.de">
    Institute for Risk and Reliability
  </a>
</footer>
