import { UserService } from '../api-service/authenticate'
import TokenService from '../api-service/storage'

import { writable, derived, get } from 'svelte/store'

import { navigate } from 'svelte-routing'

export const progress = writable(false)
export const error = writable('')
export const token = writable(TokenService.getToken())

export const isAuthenticated = derived(token, ($token) => $token !== null)

export const login = async (username, password) => {
  progress.set(true)
  error.set('')

  try {
    const t = await UserService.login({
      username,
      password,
    })
    token.set(t)
  } catch (err) {
    switch (err.errorCode) {
      case 401:
        error.set('Wrong username or password')
        break
      default:
        error.set('Unknown error')
    }
  } finally {
    progress.set(false)
    return get(isAuthenticated)
  }
}

export const logout = () => {
  UserService.logout()
  token.set(null)

  navigate('/', { replace: true })
}

export default {}
