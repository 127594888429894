<script>
  import api from '../../api-service/api'
  import { login, progress, error } from '../../store/auth-store.js'

  import { onDestroy } from 'svelte'

  import Icon from 'fa-svelte'

  import {
    faSpinner,
    faSignInAlt,
    faUser,
  } from '@fortawesome/free-solid-svg-icons'

  let username = ''
  let password = ''

  const onKeyPress = (e) => {
    if (e.charCode == 13) login(username, password)
  }
</script>

<div class="w-full">
  <form class="bg-white rounded px-8 pt-4">
    <div class="flex flex-wrap justify-center">
      <div class="w-full px-3 mb-6">
        <label
          class="block text-gray-700 text-sm font-bold mb-2"
          for="username"
        >
          Username
        </label>
        <input
          class="shadow border rounded w-full py-2 px-3
          text-gray-700 focus:outline-none focus:shadow-outline"
          id="username"
          type="text"
          bind:value="{username}"
          placeholder="Username"
          autocomplete="username"
          on:keypress="{onKeyPress}"
        />
      </div>
      <div class="w-full px-3 mb-6">
        <label
          class="block text-gray-700 text-sm font-bold mb-2"
          for="password"
        >
          Password
        </label>
        <input
          class="shadow border rounded w-full py-2 px-3
          text-gray-700 focus:outline-none focus:shadow-outline"
          id="password"
          type="password"
          bind:value="{password}"
          autocomplete="password"
          placeholder="Password"
          on:keypress="{onKeyPress}"
        />
      </div>
    </div>
    <div class="flex flex-col items-center">
      <button
        class="bg-luhblue hover:bg-blue-700 text-white font-semibold py-2 px-4 focus:outline-none rounded inline-flex items-center"
        disabled="{$progress || username == '' || password == ''}"
        class:opacity-50="{username == '' || password == ''}"
        class:cursor-not-allowed="{username == '' || password == ''}"
        type="button"
        on:click="{login(username, password)}"
      >
        {#if $progress}
          <Icon class="animate-spin w-4 mr-2" icon="{faSpinner}" />
        {:else}
          <Icon class="w-4 mr-2" icon="{faSignInAlt}" />{/if}
        <span>Login</span>
      </button>
      {#if $error}
        <p class="text-red-700 mt-2">
          {$error}
        </p>
      {/if}
    </div>
  </form>
</div>
