export const ACCESS_TOKEN = 'IRZACCESSTOKEN'

/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instace.
 * */
const TokenService = {
  getToken() {
    return localStorage.getItem(ACCESS_TOKEN)
  },

  saveToken(accessToken) {
    localStorage.setItem(ACCESS_TOKEN, accessToken)
  },

  removeToken() {
    localStorage.removeItem(ACCESS_TOKEN)
  },
}

export default TokenService
