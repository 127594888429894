import ApiService from './api'

const IpService = {
  async getIps() {
    const ips = (await ApiService.get('ips')).data
    return ips
  },
  async addIp(ip) {
    return (await ApiService.post('ip', ip)).data
  },
  async updateIp(ip) {
    return (await ApiService.put(`ip/${ip.ip}`, ip)).data
  },
  async deleteIp(ip) {
    return (await ApiService.delete(`ip/${ip}`)).data
  },
}

export default IpService
