<script>
  import Icon from 'fa-svelte'

  import { faBars } from '@fortawesome/free-solid-svg-icons'
  import { faTimes } from '@fortawesome/free-solid-svg-icons'

  export let open = false

  function openClick() {
    open = !open
  }
</script>

<button
  class="md:hidden text-gray-500 hover:text-gray-700 cursor-pointer border-none
  focus:outline-none"
  class:open
  on:click="{() => (open = !open)}"
>
  <!-- <svg width="32" height="24">
    <line id="top" x1="0" y1="2" x2="32" y2="2"></line>
    <line id="middle" x1="0" y1="12" x2="24" y2="12"></line>
    <line id="bottom" x1="0" y1="22" x2="32" y2="22"></line>
  </svg> -->
  {#if open}
    <Icon class="size w-6 h-6 mr-2" icon="{faTimes}" />
  {:else}
    <Icon class="size w-6 h-6 mr-2" icon="{faBars}" />
  {/if}
</button>
