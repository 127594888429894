<style>
  div :global(.close) {
    font-size: 22px;
  }
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.75);
    z-index: 10;
    transition: opacity 0.3s ease;
  }

  .modal {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    max-height: 80vh;
    background: white;
    border-radius: 5px;
    z-index: 100;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    overflow: auto;
  }
</style>

<script>
  import { createEventDispatcher } from 'svelte'
  import { fade } from 'svelte/transition'
  import Fa from 'fa-svelte'
  import { faTimes } from '@fortawesome/free-solid-svg-icons'

  const dispatch = createEventDispatcher()
</script>

<div class="backdrop">
  <div class="modal w-full sm:w-1/2 md:w-1/2">
    <div
      class="modal-close absolute top-0 right-0 cursor-pointer flex items-center
      mr-1 text-white text-sm"
    >
      <button
        class=" w-1/4 text-white "
        on:click|stopPropagation="{() => dispatch('close')}"
      >
        <Fa class="text-center close mr-2 mt-4" icon="{faTimes}" />
      </button>
    </div>
    <div class="wrapper bg-luhblue h-12 flex justify-center items-center">
      <header class="text-white text-center font-bold">
        <slot name="header" />
      </header>
    </div>

    <div class="content">
      <slot />
    </div>
    <footer class="flex justify-center">
      <slot name="footer" />
    </footer>
  </div>
</div>
