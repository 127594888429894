<script>
  import Icon from 'fa-svelte'
  import { createEventDispatcher } from 'svelte'
  export let id = null
  const dispatch = createEventDispatcher()
  import { faTimes } from '@fortawesome/free-solid-svg-icons'
</script>

<div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
  <div class="mt-5">
    <p class="text-md">
      Are you sure you want to delete the IP adress
      <strong>{id}</strong>
      ?
    </p>
  </div>

  <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
    <button
      type="button"
      class="w-full inline-flex justify-center rounded-md border
      border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium
      text-white hover:bg-red-700 focus:outline-none focus:ring-2
      focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
      on:click|stopPropagation="{() => dispatch('deleteIp')}"
    >
      Delete
    </button>
    <button
      type="button"
      class="mt-3 w-full inline-flex justify-center rounded-md border
      border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium
      text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2
      focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto
      sm:text-sm"
      on:click|stopPropagation="{() => dispatch('close')}"
    >
      Cancel
    </button>
  </div>
</div>
