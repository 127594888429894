export function isIP(ip) {
  if (
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      ip
    )
  ) {
    return true
  }
  return false
}

export function isEmpty(item) {
  return item.trim().length === 0
}

export function inIps(id, ip, ipList) {
  if (id) {
    return false
  }
  let i = 0
  for (i = 0; i < ipList.length; i++) {
    if (ip == ipList[i].ip) {
      console.log('is true')
      return true
    }
  }
  return false
}
