<style>
  aside {
    position: fixed;
    top: 0;
    left: -100%;
    transition: left 0.3s ease-in-out;
    height: 85%;
    z-index: 100;
  }

  .open {
    left: 0;
  }
  .bottom {
    position: absolute;
    bottom: 0;
  }
</style>

<script>
  import { Link } from 'svelte-routing'
  import { onMount } from 'svelte'
  import Fa from 'fa-svelte'

  import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
  import { logout } from '../../store/auth-store.js'

  export let open = false
  function collapse() {
    open = !open
  }
  onMount(() => {
    open = false
  })
</script>

<div class="{open ? '' : ''}">
  <aside class="md:hidden w-5/6 bg-white mr-4 shadow-lg" class:open>
    <div class="py-10 border-b flex justify-center">
      <img src="/images/logo_irz.svg" max-width="3rem" alt="Not found" />
    </div>
    <nav class="text-xl">
      <div
        class="pl-12 pb-3 pt-5 block text-grey-darker hover:text-luhblue
        hover:bg-grey-lighter"
      >
        <Link on:click="{collapse}" to="/services">Services</Link>
      </div>

      <div
        class="pl-12 pb-5 pt-3 border-b block text-grey-darker
        hover:text-luhblue hover:bg-grey-lighter"
      >
        <Link on:click="{collapse}" to="/ips">IPs</Link>
      </div>
      <div
        class="pl-12 pb-5 pt-3 border-b block text-grey-darker
        hover:text-luhblue hover:bg-grey-lighter"
      >
        <Link on:click="{collapse}" to="/users">Users</Link>
      </div>
    </nav>
    <div class="bottom pl-12 pb-3 pt-5 block pin-b items-center">
      <button
        class="self-end bg-transparent hover:bg-luhblue text-luhblue
        font-semibold hover:text-white py-2 px-4 border border-blue-500
        hover:border-transparent rounded inline-flex items-center"
        type="button"
        on:click="{logout}"
      >
        <Fa class="h-4 mr-2" icon="{faSignOutAlt}" />
        <span>Logout</span>
      </button>
    </div>
  </aside>
</div>
