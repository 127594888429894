<script>
  import ipStore from '../../store/ip-store.js'
  import { isIP, isEmpty, inIps } from '../../validations.js'
  import IpService from '../../api-service/ip'
  import ips from '../../store/ip-store.js'
  import { createEventDispatcher } from 'svelte'
  export let id = null
  let response
  let ipList
  var ip = {
    ip: '',
    host: '',
    use: '',
    location: '',
  }

  if (id) {
    const unsubscribe = ipStore.subscribe((items) => {
      const selectedIp = items.find((i) => i.ip === id)
      ip.ip = selectedIp.ip
      ip.host = selectedIp.host
      ip.use = selectedIp.use
      ip.location = selectedIp.location
    })
    unsubscribe()
  }
  const unsubscribeStore = ipStore.subscribe((items) => {
    ipList = items
  })

  let formIsValid = false

  const dispatch = createEventDispatcher()

  $: isIPAdress = isIP(ip.ip)
  $: ipValid = !isEmpty(ip.ip)
  $: hostValid = !isEmpty(ip.host)
  $: useValid = !isEmpty(ip.use)
  $: locationValid = !isEmpty(ip.location)
  $: isInIpList = inIps(id, ip.ip, ipList)
  $: formIsValid =
    isIPAdress &&
    ipValid &&
    hostValid &&
    useValid &&
    locationValid &&
    !isInIpList

  async function postIP() {
    if (!id) {
      await IpService.addIp(ip)
        .then(() => {
          dispatch('close')
          ips.addIp({
            ...ip,
          })
        })
        .catch((err) => {
          if (err.response.status === 409) {
            console.log('Error 409')
          }
        })
    } else {
      await IpService.updateIp(ip)
        .then(() => {
          dispatch('close')
          ips.updateIp({
            ...ip,
          })
        })
        .catch((err) => {
          if (err.response.status === 404) {
            console.log('Ip already exists')
          }
        })
    }
  }
</script>

<div class="w-full">
  <form
    on:submit|preventDefault="{postIP}"
    class="bg-white rounded px-8 pt-6 pb-8 mb-4"
  >
    <div class="flex flex-wrap justify-center -mx-3 mb-6">
      <div class=" w-full md:w-1/2 px-3 mb-6">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="ip">
          IP
        </label>
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3
          text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="username"
          type="text"
          bind:value="{ip.ip}"
          placeholder="IP"
        />
        {#if !isIPAdress || !ipValid}
          <p class="text-red-700">Not a valid IP adress</p>
        {/if}
        {#if isInIpList}
          <p class="text-red-700">This IP adress already exists</p>
        {/if}
      </div>
      <div class=" w-full md:w-1/2 px-3 mb-6">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="host">
          Host
        </label>
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3
          text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="host"
          type="text"
          bind:value="{ip.host}"
          placeholder="Host"
        />
        {#if !hostValid}
          <p class="text-red-700">Not a valid host</p>
        {/if}
      </div>
    </div>
    <div class="flex flex-wrap justify-center -mx-3 mb-6">
      <div class=" w-full md:w-1/2 px-3 mb-6">
        <label class="block text-gray-700 text-sm font-bold mb-2" for="use">
          Use
        </label>
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3
          text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="use"
          type="text"
          bind:value="{ip.use}"
          placeholder="Use"
        />
        {#if !useValid}
          <p class="text-red-700">Not a valid use</p>
        {/if}
      </div>
      <div class=" w-full md:w-1/2 px-3 mb-6">
        <label
          class="block text-gray-700 text-sm font-bold mb-2"
          for="location"
        >
          Location
        </label>
        <input
          class="shadow appearance-none border rounded w-full py-2 px-3
          text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="location"
          type="text"
          bind:value="{ip.location}"
          placeholder="Location"
        />

        {#if !locationValid}
          <p class="text-red-700">Not a valid location</p>
        {/if}
      </div>
    </div>
    <div class="flex justify-center items-center">
      {#if !formIsValid}
        <button
          class="bg-red-500 w-1/2 md:w-1/3 lg:w-1/4 hover:bg-red-700
          focus:outline-none text-white font-bold m-1 py-2 px-4 rounded
          cursor-not-allowed"
          disabled
        >
          Confirm
        </button>
      {:else}
        <button
          class="bg-green-500 w-1/2 md:w-1/3 lg:w-1/4 hover:bg-green-700
          focus:outline-none text-white font-bold m-1 py-2 px-4 rounded"
          type="submit"
        >
          Confirm
        </button>
      {/if}
    </div>
  </form>
</div>
