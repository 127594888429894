<script>
  import { onMount, onDestroy } from 'svelte'
  import IpItem from './IpItem.svelte'
  import Modal from '../base/Modal.svelte'
  import Alert from '../base/Alert.svelte'

  import IpForm from './IpForm.svelte'
  import IpService from '../../api-service/ip'
  import api from '../../api-service/api'
  import { ips, ipStore } from '../../store/ip-store.js'
  import Pagination from '../base/Pagination.svelte'
  import Fa from 'fa-svelte'
  import { faPlus } from '@fortawesome/free-solid-svg-icons'

  let ip
  let postIP
  let showModal = false
  let showEditModal = false
  let showAlertModal = false
  let buttonVisible = true

  let rows = []

  let loading = true

  function addIp() {
    showModal = true
  }
  function editIp(event) {
    ip = event.detail.ip
    showEditModal = true
  }
  function deleteAlert(event) {
    ip = event.detail.ip
    showAlertModal = true
  }
  async function deleteIp() {
    await IpService.deleteIp(ip)
      .then(() => {
        showAlertModal = false
        ipStore.removeIp(ip)
      })
      .catch(() => {
        console.log('Not able to delete IP')
      })
  }

  onMount(async () => {
    $ips = await IpService.getIps()
  })

  let filter = ''

  $: filteredIps =
    filter != ''
      ? $ips.filter(
          (ip) =>
            ip.ip.includes(filter) ||
            ip.host.includes(filter) ||
            ip.use.includes(filter) ||
            ip.location.includes(filter)
        )
      : $ips
</script>

<div class="container mx-auto mt-6">
  <div class="flex mb-2">
    <input
      type="text"
      bind:value="{filter}"
      placeholder="Filter..."
      class="form-input h-12 rounded border-gray-300 shadow flex-auto p-2"
    />
    <button
      type="button"
      class="ml-2 rounded bg-luhblue p-2"
      on:click="{addIp}"
    >
      <Fa class="w-8 h-8 text-white" icon="{faPlus}" />
    </button>
  </div>
  <table class="w-full items-center text-md bg-white mb-2">
    <tr class="rounded mb-4">
      <td class="text-left p-3 px-5">IP Address</td>
      <td class="text-left p-3 px-5">Hostname</td>
      <td class="text-left p-3 px-5">Use</td>
      <td class="text-left p-3 px-5">Location</td>
    </tr>
    {#if $ips}
      {#each filteredIps as ip}
        <IpItem
          ip="{ip.ip}"
          host="{ip.host}"
          use="{ip.use}"
          location="{ip.location}"
          on:edit="{editIp}"
          on:delete="{deleteAlert}"
        />
      {/each}
    {:else}
      <p class="loading">loading...</p>
    {/if}
  </table>
</div>
{#if showModal}
  <Modal
    on:cancel="{(event) => (showModal = false)}"
    on:close="{() => (showModal = false)}"
    on:click="{postIP}"
  >
    <h1 slot="header">Add new IP adress</h1>
    <IpForm id="" bind:postIP on:close="{() => (showModal = false)}" />
  </Modal>
{/if}
{#if showEditModal}
  <Modal
    on:cancel="{(event) => (showEditModal = false)}"
    on:close="{() => (showEditModal = false)}"
    on:click="{postIP}"
  >
    <h1 slot="header">Edit IP Adress</h1>
    <IpForm id="{ip}" bind:postIP on:close="{() => (showEditModal = false)}" />
  </Modal>
{/if}
{#if showAlertModal}
  <Modal
    on:cancel="{(event) => (showAlertModal = false)}"
    on:close="{() => (showAlertModal = false)}"
    on:click="{postIP}"
  >
    <h1 slot="header">Delete IP Adress</h1>
    <Alert
      id="{ip}"
      on:close="{() => (showAlertModal = false)}"
      on:deleteIp="{deleteIp}"
    />
  </Modal>
{/if}
