<script>
  import { onMount } from 'svelte'
  import LoginForm from './LoginForm.svelte'
  import { isAuthenticated, token } from '../../store/auth-store.js'
  import { navigate } from 'svelte-routing'

  $: if ($isAuthenticated) {
    navigate('/services', { replace: true })
  }

  onMount(async () => {
    if ($isAuthenticated) {
      navigate('/services', { replace: true })
    }
  })
</script>

<div class="container mx-auto mt-8">
  <div class="w-full max-w-lg m-auto p-4 bg-white rounded shadow">
    <img
      class="m-auto h-20 my-4"
      src="images/logo_irz.svg"
      alt="Institute Logo"
    />
    <hr />
    <LoginForm />
  </div>
</div>
