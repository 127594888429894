import { writable } from 'svelte/store'

export const ips = writable([])

export const ipStore = {
  subscribe: ips.subscribe,
  setIps: (ipArray) => {
    ips.set(ipArray)
  },
  addIp: (ipData) => {
    const newIp = {
      ...ipData,
    }
    ips.update((items) => {
      return [newIp, ...items]
    })
  },
  updateIp: (ipData) => {
    ips.update((items) => {
      const ipIndex = items.findIndex((i) => i.ip === ipData.ip)
      const updatedIp = { ...items[ipIndex], ...ipData }
      const updatedIps = [...items]
      updatedIps[ipIndex] = updatedIp
      return updatedIps
    })
  },
  removeIp: (ip) => {
    ips.update((items) => {
      return items.filter((i) => i.ip !== ip)
    })
  },
}

export default ipStore
