<script>
  import Fa from 'fa-svelte'
  import { faUser } from '@fortawesome/free-solid-svg-icons'
  import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
  import { faPhone } from '@fortawesome/free-solid-svg-icons'
  import { faLaptop } from '@fortawesome/free-solid-svg-icons'
  import ServiceItem from './ServiceItem.svelte'
  let buttonVisible = true
  const services = [
    {
      title: 'Gitlab',
      icon: '/images/gitlab.svg',
      description:
        'Gitlab provides Git repository management, code reviews, issue tracking, code testing, deployment, activity feeds and wikis.',
      link: 'https://gitlab.irz.uni-hannover.de',
      contact: 'Jasper',
      number: '4289',
      mail: 'behrensdorf@irz.uni-hannover.de',
    },
    {
      icon: '/images/mattermost.png',
      title: 'Mattermost',
      description:
        'Mattermost is a team communication service for sharing messages and files across PCs and phones.',
      link: 'https://mattermost.irz.uni-hannover.de',
      contact: 'Jasper',
      number: '4289',
      mail: 'behrensdorf@irz.uni-hannover.de',
    },
    {
      icon: '/images/seafile.png',
      title: 'Seafile',
      description:
        'Seafile is an open source, self-hosted file sync and share solution with high performance and reliability.',
      link: 'https://seafile.irz.uni-hannover.de',
      contact: 'Matteo',
      number: '4289',
      mail: 'broggi@irz.uni-hannover.de',
    },
    {
      icon: '/images/sogo.png',
      title: 'Calendar',
      description:
        'The university provides a calendar service  where calendars can be shared and appointments scheduled.',
      link: 'https://kalender.uni-hannover.de',
      contact: 'Christoph',
      number: '14676',
      mail: 'eckert@irz.uni-hannover.de',
      info: 'https://seafile.irz.uni-hannover.de/f/eeed9126bd/?raw=1',
    },
  ]
  function navigate(event) {
    window.open('https://www.irz.uni-hannover.de/', '_blank')
  }
</script>

<div class="container mx-auto grid lg:grid-cols-2 gap-4">
  {#each services as service}
    <ServiceItem
      title="{service.title}"
      icon="{service.icon}"
      link="{service.link}"
      description="{service.description}"
      contact="{service.contact}"
      number="{service.number}"
      mail-string="{service.mailString}"
      mail="{service.mail}"
    />
  {/each}
</div>
