import ApiService from './api'
import TokenService from './storage'

export const UserService = {
  /**
   * Login the user and store the access token to TokenService.
   *
   * @returns access_token
   * @throws AuthenticationError
   * */
  async login(credentials) {
    try {
      const res = (await ApiService.post('authenticate', credentials)).data
      const token = res.token

      TokenService.saveToken(token)
      ApiService.setAuthorizationHeader(token)
      return token
    } catch (err) {
      throw Error(err)
    }
  },

  /**
   * Logout the current user by removing the token from storage.
   * */
  logout() {
    // Remove the token and remove Authorization header from Api Service as well
    TokenService.removeToken()
    ApiService.removeAuthorizationHeader()

    // ApiService.unmount401Interceptor();
  },
}

export default UserService
