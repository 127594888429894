<script>
  export let ip
  export let host
  export let use
  export let location
  import ipStore from '../../store/ip-store.js'
  import IpService from '../../api-service/ip'

  import Fa from 'fa-svelte'
  import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'

  import { createEventDispatcher } from 'svelte'
  import Alert from '../base/Alert.svelte'
  let alert = false
  const dispatch = createEventDispatcher()

  function openAlert() {
    alert = true
  }
</script>

<tr
  class=" hover:bg-grey-light bg-gray-100 rounded"
  style="cursor:pointer"
  on:click="{() => dispatch('edit', { ip })}"
>
  <td class="bg-gray px-4 py-2 text-sm">{ip}</td>
  <td class="bg-gray px-4 py-2 text-sm">{host}</td>
  <td class="bg-gray px-4 py-2 text-sm">{use}</td>
  <td class="bg-gray px-4 py-2 text-sm">{location}</td>

  <td class="table-cell p-3 px-5 flex justify-end">
    <button
      on:click|stopPropagation="{() => dispatch('delete', { ip })}"
      class="float-right text-sm text-red py-1
      px-2 focus:outline-none"
    >
      <Fa class="hover:text-red-500" icon="{faCircleXmark}" />
    </button>
  </td>
</tr>
